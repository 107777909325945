import React from "react";
import styles from "../styles.module.css";
import darkPhone from "./images/chat_01.png";
import lightPhone from "./images/chat_02.png";
import classnames from "classnames";
import { useTranslationContext } from "../../TranslationsContext";

export const CentralSection = () => {
  const translations = useTranslationContext();

  return (
    <div className={classnames(styles.root, styles.centerBackground)}>
      <div className={styles.columns}>
      <div className={styles.relative}>
        <img className={styles.darkPhone} src={darkPhone} alt="phonelogo"/>
        <img className={styles.lightPhone} src={lightPhone} alt="phonelogo"/>
      </div>
      <div className={styles.rows}>
        <p className={styles.bigText}>{translations["Our work to create safe and beneficial AI"]}</p>
        <p className={styles.smallText}>{translations["Our work to create safe and beneficial AI requires a deep understanding of the potential risks and benefits, as well as careful consideration of the impact."]}</p>
      </div>
      </div>
    </div>
  )
};
