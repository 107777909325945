import React from "react";
import classnames from "classnames";
import styles from "../styles.module.css";
import logoIcon from "../../images/logo.svg";
import apple from "./images/apple.png";
import { useTranslationContext } from "../../TranslationsContext";

export const TopSection = () => {
  const translations = useTranslationContext();

  return (
    <div className={classnames(styles.root, styles.topBackground)}>

      <header className={styles.columns}>
        <a href="/">
        <div className={styles.title}>
          <img src={logoIcon} alt="Logo image"/>
          <p>BardAI</p>
        </div>
        </a>
        <div className={styles.headerLinks}>
          <a href="/contacts" className={styles.link}>
           Contact Us
          </a>
          <a href="/policy" className={styles.link}>
            Privacy & Policy
          </a>
          <a href="/terms" className={styles.link}>
            Terms of use
          </a>
        </div>
      </header>

      <div className={styles.columns}>
        <div className={styles.rows}>
          <p className={styles.bigText}>{translations["Introducing you BardAI, most advanced AI application"]}</p>
          <span className={styles.smallText}>
            {translations["We’ve trained a model called AiChat which interacts in a conversational way."]}
          </span>
          <button className={styles.button}>
            <img src={apple} />
          </button>
        </div>

      </div>
    </div>
  )
};