
import { TopSection, CentralSection, BottomSection, Footer, Contacts, Terms, Privacy } from "./sections";
import "./App.css";
import { TranslationContextProvider } from "./TranslationsContext";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: (<>
      <TopSection />
      <CentralSection />
      <BottomSection />
      <Footer />
    </>)
  },
  {
    path: "/contacts",
    element: (<>
      <Contacts />
      <Footer />
    </>)
  },
  {
    path: "/policy",
    element: (<>
      <Privacy />
      <Footer />
    </>)
  },
  {
    path: "/terms",
    element: (<>
      <Terms />
      <Footer />
    </>)
  }
]);

function App() {
  return (
    <div className="App">
      <TranslationContextProvider>
        <RouterProvider router={router} />
      </TranslationContextProvider>
    </div>
  );
}

export default App;
