import React from "react";
import classnames from "classnames";
import styles from "../styles.module.css";
import centralPhone from "./images/center-phone.png";
import leftPhone from "./images/left-phone.png";
import { useTranslationContext } from "../../TranslationsContext";


export const BottomSection = () => {
  const translations = useTranslationContext();

  return (
    <div className={classnames(styles.bottomBackground, styles.relative)}>
      <div className={styles.blur}/> 
      <div className={styles.coloredBlur} />
      {/* <div className={styles.root,} */}
      <div className={classnames(styles.root, styles.zindex, styles.withoutpadding)}>
        <div className={classnames(styles.columns, styles.center)}>
          <p className={classnames(styles.bigText, styles.zindex)}>
            {translations["We research generative models and how to align them with human values."]}
          </p>
          <div className={classnames(styles.relative, styles.zindex)}>
            <img className={styles.darkPhone} src={leftPhone}/>
            <img className={styles.lightPhone} src={centralPhone}/>
          </div>
        </div>
      </div>
      
      
    </div>
  )
};