import React, { useContext, createContext, ReactNode } from "react";
import translations from "./translations.json";

const initialValue = translations["en"];

export const TranslationContext = createContext(initialValue);

export interface TranslationContextProviderProps {
  children: ReactNode;
}
export const TranslationContextProvider = ({children}: TranslationContextProviderProps) => {
  const query = new URLSearchParams(window.location.search).get("lang");
  const lanquage = translations[query as "en"] || translations["en"];

  return (
    <TranslationContext.Provider value={lanquage}>{children}</TranslationContext.Provider>
  )
};

export const useTranslationContext = () => useContext(TranslationContext);
