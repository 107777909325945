import React from "react";
import styles from "./styles.module.css";
import { useTranslationContext } from "../TranslationsContext";
import classNames from "classnames";

export const Footer = () => {
  const translations = useTranslationContext();
  
  return (
    <footer className={styles.footer}>
      <div className={classNames(styles.columns, styles.footerLinksBlock)}>
        <a href="/terms">{translations["Terms & Conditions"]}</a>
        <a href="/policy">{translations["Privacy Policy"]}</a>
      </div>
      <p className={styles.smallText}>© Copyright 2023 Bard AI LTD  All Rights Reserved.</p>
      
     
    </footer>
  )
};

