import React from "react";
import styles from "../styles.module.css";
import { useForm } from "react-hook-form";
// import { useTranslationContext } from "../../TranslationsContext";
import logoIcon from "../../images/logo.svg";
import classNames from "classnames";
import { useFormspark } from "@formspark/use-formspark";
import contactStyles from "./styles.module.css";

type FormValues = {
  name: string;
  message: string;
  email: string;
};

export const Contacts = () => {
  // const translations = useTranslationContext();
  const { register, handleSubmit, formState } = useForm<FormValues>();
  const [submit, submitting] = useFormspark({formId: "7YAKbZin"});

  const onSubmit = (data: any) => {
    submit(data);
  }
  return (
    <div className={classNames(styles.root, styles.topBackground, contactStyles.fullscreen)}>
      <header className={styles.columns}>
        <a href="/">
        <div className={styles.title}>
          <img src={logoIcon} alt="Logo image"/>
          <p>BardAI</p>
        </div>
        </a>
        <div className={styles.headerLinks}>
          <a href="/contacts" className={styles.link}>
           Contact Us
          </a>
          <a href="/policy" className={styles.link}>
            Privacy & Policy
          </a>
          <a href="/terms" className={styles.link}>
            Terms of use
          </a>
        </div>
  
      </header>
      <div className={contactStyles.center}>
        <div className={classNames(styles.rows, contactStyles.baseline)}>
          <p className={styles.bigText}>Contact Us</p>
          <p className={styles.smallText}>if you are having any issues inside the app or require any kind of support, please contact us via this form. We will get back to you in less than 24 hours.You can contact us through:
            <a href="mailto:support@bardchat.live"> support@bardchat.live</a>
          </p>
          <div>
            <p className={contactStyles.title}>Correspondence address:</p>
            <span className={styles.smallText}> 71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ</span>
          </div>
          <div>
            <p className={contactStyles.title}>Tel: +44 7460 988433</p>
            <span className={styles.smallText}>(between 8.30am and 5.00pm Monday to Friday)</span>
          </div>
          <form className={contactStyles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={contactStyles.fieldWrapper}>
              <input 
                placeholder="Name" 
                className={contactStyles.textfield} 
                {...register("name", {required: "Please fill this field",})}
              />
              <label className={contactStyles.error}>{formState.errors.name?.message}</label>
            </div>
            
            <div className={contactStyles.fieldWrapper}>
              <input 
                placeholder="Email" 
                className={contactStyles.textfield} 
                {...register("email", {required: "Please fill this field",})}
              />
              <label className={contactStyles.error}>{formState.errors.email?.message}</label>
            </div>
    
            <div className={contactStyles.fieldWrapper}>
              <textarea 
                placeholder="Message" 
                className={classNames(contactStyles.textfield, contactStyles.textarea)} 
                {...register("message", {required: "Please fill this field",})}
              />
              <label className={contactStyles.error}>{formState.errors.message?.message}</label>
            </div>
    
            <button className={contactStyles.button} disabled={submitting} type="submit">Send</button>
          </form>
        </div>
      </div>    
    </div>
  )
};
